import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../flash/flash-service";
import {I18N} from "aurelia-i18n";

@inject(
    Client,
    DialogService,
    FlashService,
    I18N
)
@customElement('sio-booking-assistant')
export class BookingAssistant {

    client;
    dialogService;
    flash;
    form;
    i18n;

    constructor(client, dialogService, flash, i18n) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.i18n = i18n;

        this.submit = this.submit.bind(this);
    }

    async submit() {

        this.searchId = null;
        this._isSubmitting = true;

        this.data = null;

        try {
            let value = this.form.formService.getValue();

            let result = (await this.client.post('order-booking-assistant/search', value));

            this._isSubmitting = false;

            if (!result.data.searchId) {
                this.flash.error('Es konnte kein Suchergebnis gefunden werden');
            }

            this.searchId = result.data.searchId;
        } catch (err) {

            this.searchId = null;
            this._isSubmitting = false;

            let errors = [];

            if (err && err.data) {
                if (err.data.errors && err.data.errors.children) {
                    for (const [key, value] of Object.entries(err.data.errors.children)) {
                        if (value.errors) {
                            let field = this.i18n.tr(`booking-assistant.field.${key}`);
                            errors.push(`${field}: ${value.errors[0]}`);
                        }
                    }
                }

                if (errors.length === 0 && err.data.localizedMessage) {
                    errors.push(err.data.localizedMessage);
                }

                if (errors.length === 0 && err.data.message) {
                    errors.push(err.data.message);
                }
            }

            errors.forEach(error => {
                this.flash.error(error || 'Ein unbekannter Fehler ist aufgetreten.');
            });
        }
    }
}
